import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from "../../../core/services/config.service";
import { LanguageService } from "../../../core/services/language.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { VisibilityService } from "../../../core/services/visibility.service";

@Component({
    selector: 'app-languages',
    templateUrl: './languages.component.html',
    styleUrl: './languages.component.scss'
})
export class LanguagesComponent implements OnInit, OnDestroy {
    selectedLanguage: any;
    languages: any[] = [];
    isInteractionAllowed!: boolean;
    isVisibleMenu!: boolean;

    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;
    visibilitySubscription!: Subscription;

    @HostListener('document:click', ['$event'])
    clickoutLangMenu(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isVisibleMenu = false;
        }
    }

    constructor(
        private elementRef: ElementRef,
        private config: ConfigService,
        private language: LanguageService,
        private interaction: InteractionService,
        private visibility: VisibilityService) {
    }

    ngOnInit(): void {
        this.languages = this.config.getLanguages();

        this.visibilitySubscription = this.visibility.getVisibility('avatar-language-menu').subscribe(visible => {
            this.isVisibleMenu = visible;
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            this.selectedLanguage = selectedLanguage;
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.languages.forEach(language => {
                language.name = translate.typography.languageNames[language.locale];
            });
        });
    }

    getClass(language: any) {
        return {
            'selected': language === this.selectedLanguage,
            'active': language === this.selectedLanguage
        };
    }

    toggleDropdown() {
        this.visibility.toggleComponent('avatar-language-menu');
    }

    toggleLanguage(selectedMenuLanguage: any) {
        this.selectedLanguage = selectedMenuLanguage;
        this.language.setLanguage(selectedMenuLanguage.locale);
    }

    ngOnDestroy(): void {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.visibilitySubscription) { this.visibilitySubscription.unsubscribe(); }
    }
}
