import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import {ConfigService} from "./config.service";

interface HTMLCustomAudioElement extends HTMLAudioElement {
    isBeep?: boolean;
    isBeepDone?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AudioService {
    private audioObj: HTMLCustomAudioElement = new Audio();
    public onAudioLoaded = new EventEmitter<boolean>();
    public isAudioPlaying = new BehaviorSubject<{ isPlaying: boolean, isBeep: boolean }>({
        isPlaying: false,
        isBeep: false,
    });
    private beepAudioSrc = 'assets/audio/beep.wav';

    constructor(private config: ConfigService) {
        this.audioLoadedHandler = this.audioLoadedHandler.bind(this);
        this.audioErrorHandler = this.audioErrorHandler.bind(this);

        this.audioObj.isBeep = false;
        this.audioObj.isBeepDone = false;
    }

    // getters
    getAudioObj() {
        return this.audioObj;
    }

    // setters
    setAudioObj(audioObj: any) {
        this.audioObj = audioObj;
    }

    // methods
    private audioLoadedHandler() {
        this.onAudioLoaded.emit(true); // Notify that audio is loaded and ready
        this.audioObj.play().then();
        // Remove listeners after they have been invoked
        this.removeEventListeners();
    }

    private audioErrorHandler() {
        this.onAudioLoaded.emit(false); // Notify if there was an error loading the audio
        this.removeEventListeners();
    }

    private removeEventListeners() {
        this.audioObj.removeEventListener('canplaythrough', this.audioLoadedHandler);
        this.audioObj.removeEventListener('error', this.audioErrorHandler);
    }

    setupAudio(url: string) {
        this.audioObj.src = url;
        this.audioObj.load();

        this.audioObj.onplay = () => {
            // @ts-ignore
            this.isAudioPlaying.next({isBeep: this.audioObj.isBeep, isPlaying: true});
            this.setAudioObj(this.audioObj);
            this.onAudioLoaded.emit(true);
            this.removeEventListeners();
        };

        this.audioObj.onended = () => {
            this.stopInteraction();
        }
    }

    playAudio () {
        this.audioObj.play().then(() => {
            console.log('Audio playing');
        }).catch((error) => {
            console.error('Error playing audio', error);
        });
    }

    public playBeepAudio(): void {
        if (this.audioObj.isBeepDone) {
            return;
        }

        this.audioObj.isBeep = true;
        this.setupAudio(this.beepAudioSrc);
        this.playAudio();
    }

    stopInteraction () {
        // @ts-ignore
        this.isAudioPlaying.next({isBeep: this.audioObj.isBeep, isPlaying: false});

        this.stop();
    }

    stop() {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        if (this.audioObj.isBeep !== false) {
            this.audioObj.isBeep = false;
            this.audioObj.isBeepDone = true;
        }
    }
}

