import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Subscription } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";

import { MessageService } from "../../../core/services/message.service";
import { EventService } from "../../../core/services/event.service";
import { LanguageService } from "../../../core/services/language.service";
import { MessageDisplayFormat } from "../../../core/models/messageDisplayFormat";
import { InteractionService } from "../../../core/services/interaction.service";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent implements OnInit, OnDestroy {
    companyLogo: string = "assets/icons/logo.svg";
    tooltipCopy: string = 'Copy';
    tooltipLike: string = 'Good response';
    tooltipDislike: string = 'Bad response';
    tooltipEdit: string = 'Edit message';
    cancelButton: string = 'Cancel';
    sendButton: string = 'Send';

    hoveredMessageIndex: number | null = null;

    editingMessageIndex: number | null = null;
    isMessageCopied: boolean[] = [];
    messages: MessageDisplayFormat[] = [];
    isDislikeButtonClicked: boolean[] = [];
    isLikeButtonClicked: boolean[] = [];
    talkStarted: boolean = false;
    isEditing: boolean = false;
    editingMessageContent!: string;
    isInteractionAllowed: boolean = false;

    translateSubscription!: Subscription;
    talkSubscription!: Subscription;
    interactionSubscription!: Subscription;
    editSubscription!: Subscription;

    relatedContentTitle: string = 'Related Content:';

    constructor(
        private event: EventService,
        protected message: MessageService,
        private language: LanguageService,
        private clipboard: Clipboard,
        private interaction: InteractionService
    ) { }

    ngOnInit(): void {
        this.messages = this.message.getMessages();

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.event.dislikeButtonClicked.subscribe(index => {
            this.isDislikeButtonClicked[index] = false;
        });

        this.event.feedbackSubmitted.subscribe(index => {
            this.isDislikeButtonClicked[index] = true;
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.editSubscription = this.event.getStartedEdit().subscribe(isEditing => {
            this.isEditing = isEditing;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            Object.assign(this, translate.typography);
        });
    }

    onMouseEnter(index: number): void {
        this.hoveredMessageIndex = index;
    }

    onMouseLeave(): void {
        this.hoveredMessageIndex = null;
    }

    copyTextMessage(content: string, index: number) {
        this.clipboard.copy(content);
        this.isMessageCopied[index] = true;

        setTimeout(() => { this.isMessageCopied[index] = false; }, 3000);
    }

    positiveReaction(index: number) {
        this.message.setMessageFeedback(index, true);
        this.isLikeButtonClicked[index] = true;
    }

    negativeReaction(index: number) {
        this.message.setMessageIndex(index);
        this.event.blurEfectEvent.emit(true);
        this.event.dislikeButtonClicked.emit(index);
    }

    editClientMessage(index: number, content: string) {
        this.event.setStartedEdit(true);
        this.editingMessageIndex = index;
        this.editingMessageContent = content;
    }

    async sendEditedMessage(index: number) {
        if (!this.messageFilled()) return;
        await this.message.editMessage(index, this.editingMessageContent);
        this.resetFields();
    }

    cancelEditing() {
        this.event.setStartedEdit(false);
        this.editingMessageIndex = null;
    }

    messageFilled(): boolean {
        return !!(this.editingMessageContent && this.editingMessageContent.trim() !== "");
    }

    resetFields() {
        this.editingMessageContent = "";
        this.event.setStartedEdit(false);
        this.editingMessageIndex = null;
    }

    ngOnDestroy() {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.editSubscription) { this.editSubscription.unsubscribe(); }
    }
}
