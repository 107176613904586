import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";

import { LanguageService } from "../../../core/services/language.service";
import { EventService } from "../../../core/services/event.service";
import { MicrophoneService } from "../../../core/services/microphone.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { MessageService } from "../../../core/services/message.service";
import { AnimationService } from "../../../core/services/animation.service";
import {AudioService} from "../../../core/services/audio.service";

@Component({
  selector: 'app-character',
  templateUrl: './character.component.html',
  styleUrl: './character.component.scss'
})
export class CharacterComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('animationContainer') animationContainer!: ElementRef;

    bubbleText: string[] = [ "Hello, I'm Exy. Your Digital Assistant.", "Click on me to start a call'" ];

    talkStarted!: boolean;
    isEditing: boolean = false;
    isInteractionAllowed!: boolean;

    translateSubscription!: Subscription;
    talkSubscription!: Subscription;
    interactionSubscription!: Subscription;
    visibilitySubscription!: Subscription;
    editSubscription!: Subscription;

    constructor(
        private language: LanguageService,
        protected event: EventService,
        protected microphone: MicrophoneService,
        private changeDetector: ChangeDetectorRef,
        private interaction: InteractionService,
        private visibility: VisibilityService,
        private message: MessageService,
        private animation: AnimationService,
        protected audio: AudioService
    ) { }

    ngOnInit(): void {
        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.bubbleText =  translate.typography.bubbleText;
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
            if (this.talkStarted) {
                this.animation.onResize();
            }
        });

        this.editSubscription = this.event.getStartedEdit().subscribe(isEditing => {
            this.isEditing = isEditing;
        });
    }

    async ngAfterViewInit() {
        await this.animation.init(this.animationContainer);
        this.animation.startDefaultAnimation();
    }

    async startTalk() {
        this.event.setStartedTalk(true);
        this.visibility.showComponent('avatar-conversation');
        this.event.scrollToBottomEvent.emit();
        await this.onMicrophoneClick(true)
    }

    async endTalk() {
        if (this.message.getMessages().length === 0) {
            this.visibility.hideComponent("avatar-conversation");
        }

        await this.onMicrophoneClick(false);
        this.event.setStartedTalk(false);
    }

    async interactCharacter() {
        if (!this.audio.isAudioPlaying.getValue().isPlaying) {
            this.microphone.resetMicrophone();
            await this.onMicrophoneClick(true);
        } else {
            const audioObj = this.audio.getAudioObj();
            console.log(audioObj.paused, audioObj);
            if (!audioObj.paused) {
                this.audio.stopInteraction();
            }
        }
    }

    async onMicrophoneClick(micState: boolean) {
        this.microphone.setRobotTalkingStatus(micState);
        await this.microphone.onMicrophoneClick(this.changeDetector, micState);
    }

    ngOnDestroy(): void {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.talkSubscription){ this.talkSubscription.unsubscribe(); }
        if (this.interactionSubscription){ this.interactionSubscription.unsubscribe(); }
        if (this.visibilitySubscription){ this.visibilitySubscription.unsubscribe(); }
        if (this.editSubscription){ this.editSubscription.unsubscribe(); }
    }
}
