import { Application, Container, Graphics } from 'pixi.js';

import { loadingAnimationStruct, AnimationStruct } from './structure';  // Adjust the import path as needed

export class ExaLoadingAnimation {
    app: Application;
    container!: Container;
    scaleMin: number;
    scaleMax: number;
    animationDuration: number;
    containerChildren: AnimationStruct['containerChildren'];
    containerChildrenMargin: number;

    constructor(app: Application) {
        this.app = app;
        // @ts-ignore
        this.scaleMin = loadingAnimationStruct.scaleMin;
        // @ts-ignore
        this.scaleMax = loadingAnimationStruct.scaleMax;
        // @ts-ignore
        this.animationDuration = loadingAnimationStruct.animationDuration;
        this.containerChildren = loadingAnimationStruct.containerChildren;
        this.containerChildrenMargin = loadingAnimationStruct.containerChildrenMargin;
    }

    init() {
        this.container = new Container();
        this.app.stage.addChild(this.container);
        this.render();
        this.app.ticker.add(this.animate, this);
    }

    render() {
        this.prepare();

        let lastKnownX = 0;
        const maxSphereWidth = this.getMaxSphereWidth();

        this.containerChildren.forEach(asset => {
            // @ts-ignore
            asset.graphic.y = (maxSphereWidth - asset.graphic.width) / 2;
            // @ts-ignore
            asset.graphic.alpha = asset.scaling;
            // @ts-ignore
            asset.graphic.x = lastKnownX > 0 ? lastKnownX + (maxSphereWidth - asset.graphic.width) / 2 : 0;

            // @ts-ignore
            this.setContainerChild(asset.graphic);
            lastKnownX += maxSphereWidth;
        });

        this.setContainerPivotXY(
            maxSphereWidth * this.containerChildren.length / 3,
            this.container.width / 4
        );

        this.setContainerXY(
            this.app.renderer.width / 2,
            this.app.renderer.height / 2
        );
    }

    prepare() {
        this.containerChildren = this.containerChildren.map(asset => {
            asset.graphic = new Graphics();
            asset.graphic.fill(0x5598FE);
            // @ts-ignore
            asset.graphic.circle(0, 0, asset.radius);
            asset.graphic.fill();

            return asset;
        });
    }

    setContainerXY(x: number, y: number) {
        this.container.x = x;
        this.container.y = y;
    }

    setContainerPivotXY(x: number, y: number) {
        this.container.pivot.x = x;
        this.container.pivot.y = y;
    }

    setContainerChild(graphic: Graphics) {
        this.container.addChild(graphic);
    }

    getMaxSphereWidth() {
        let maxSphereWidth = 0;
        this.containerChildren.forEach(asset => {
            // @ts-ignore
            maxSphereWidth = Math.max(maxSphereWidth, asset.graphic.width);
        });

        return maxSphereWidth + this.containerChildrenMargin;
    }

    destroy() {
        this.app.ticker.remove(this.animate, this);
        this.app.stage.removeChild(this.container);
        this.container.destroy({ children: true });
    }

    animate() {
        const value = performance.now() / 1000;
        const scaleMin = this.scaleMin;
        const scaleMax = this.scaleMax;
        const animationDuration = this.animationDuration;
        const containerChildren = this.containerChildren;

        containerChildren.forEach((asset, assetIndex) => {
            if (asset.graphic) {
                const scale = scaleMin + (scaleMax - scaleMin) * (0.5 + 0.5 * Math.sin((value + assetIndex * (animationDuration / containerChildren.length)) * Math.PI * 2 / animationDuration));
                asset.graphic.alpha = scale;
                asset.graphic.scale.set(scale);
            }
        });
    }
}
