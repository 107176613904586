import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";

import { SoundWaveService } from "../../../core/services/soundwave.service";
import { MicrophoneService } from "../../../core/services/microphone.service";
import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-soundwave',
    templateUrl: './soundwave.component.html',
    styleUrl: './soundwave.component.scss'
})
export class SoundwaveComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('soundWaveRender') soundWaveRenderElement!: ElementRef;
    soundWaveList: string[] = [];
    talkStarted!: boolean;

    soundwaveSubscription!: Subscription;
    talkSubscription!: Subscription;
    waveAnimationRenderSubscription!: Subscription

    constructor(
        private soundwave: SoundWaveService,
        private microphone: MicrophoneService,
        private cdr: ChangeDetectorRef,
        private event: EventService
    ) { }

    ngOnInit(): void {
        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        // Get updated soundwave list of bars
        this.soundwaveSubscription = this.soundwave.soundWaveList.subscribe(list => {
            this.soundWaveList = list;
            //this.cdr.detectChanges(); // Manually trigger change detection
        });

        this.waveAnimationRenderSubscription = this.microphone.waveAnimationRenderEvent
        .subscribe(probability => {
            this.soundwave.setSoundWaveHeight(probability.isSpeech)
            this.soundwave.updateSoundWaveList();
        });
    }

    ngAfterViewInit(): void {
        this.soundwave.setSoundWaveRenderElement(this.soundWaveRenderElement);
    }

    ngOnDestroy(): void {
        if (this.soundwaveSubscription) { this.soundwaveSubscription.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
        if (this.waveAnimationRenderSubscription) { this.waveAnimationRenderSubscription.unsubscribe(); }
    }
}
