import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-microphone',
    template: `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="13.1592" y="7.3999" width="5.68" height="10.8" rx="2.84" [attr.stroke]="stroke" stroke-width="2"/>
            <path d="M22.4001 14.77C22.4001 18.8484 19.5347 22.1546 16.0001 22.1546C12.4655 22.1546 9.6001 18.8484 9.6001 14.77" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round"/>
            <path d="M16.001 22.1538V25.6" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round"/>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-controls-color: #D9D9D9;
        }
        svg path {
            stroke: var(--stroke-controls-color);
        }
    `]
})
export class MicrophoneComponent {
    @Input() stroke: string = 'var(--stroke-controls-color)';
}
