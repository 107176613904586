<div *ngIf="isVisible" class="box-introduction">
    <div class="introduction-wrapper">
        <div class="introduction-text">
            <h1>{{ helloHeader }}</h1>
            <h2>{{ assistHeader }}</h2>
        </div>
        <div class="horizontal-scroll-container">
            <app-shortcuts></app-shortcuts>
        </div>
    </div>
</div>
