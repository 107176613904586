import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {
    private visibilityMap = new Map<string, BehaviorSubject<boolean>>();

    constructor() { }

    private getSubjectForKey(key: string): BehaviorSubject<boolean> {
        if (!this.visibilityMap.has(key)) {
            this.visibilityMap.set(key, new BehaviorSubject<boolean>(false));
        }
        return this.visibilityMap.get(key)!;
    }

    // Function to get the visibility state Observable of a component
    getVisibility(key: string): Observable<boolean> {
        return this.getSubjectForKey(key).asObservable();
    }

    // Function to show a component
    showComponent(key: string): void {
        const subject = this.getSubjectForKey(key);
        if (!subject.value) {
            subject.next(true);
        }
    }

    // Function to hide a component
    hideComponent(key: string): void {
        const subject = this.getSubjectForKey(key);
        if (subject.value) {
            subject.next(false);
        }
    }

    isShown(key: string): boolean {
        const subject = this.getSubjectForKey(key);
        return subject.value;
    }

    // Function to toggle visibility of a component
    toggleComponent(key: string): void {
        const subject = this.getSubjectForKey(key);
        subject.next(!subject.value);
    }
}
