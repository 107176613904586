<div class="box-character" [ngClass]="{'talk-started': talkStarted}">
    <div class="character-container">
        <div class="character">
            <div class="character-inside">
                <div class="character-bubble">
                    <p class="bubble-text">{{ bubbleText[0] }}</p>
                    <p class="bubble-text">{{ bubbleText[1] }}</p>
                </div>
                <button #animationContainer
                        [disabled]="!isInteractionAllowed || talkStarted || isEditing"
                        id="id-start-call-button" class="start-call-button"
                        (click)="startTalk()">
                    <!--<img class="character-img" [src]="characterImage" alt="Character image" />-->
                </button>
                <div class="character-shadow"></div>
                <div class="soundwave-container" [ngStyle]="{'visibility': microphone.getMicrophoneTalkStatus() ? 'visible' : 'hidden'}">
                    <app-soundwave></app-soundwave>
                </div>
            </div>
            <div class="character-buttons">
                <button [disabled]="!isInteractionAllowed" id="id-close-call" class="end-talk-button" type="button" (click)="endTalk()">
                    <app-close width="28" height="28"></app-close>
                </button>
                <button [disabled]="!isInteractionAllowed && !audio.isAudioPlaying.getValue().isPlaying && !microphone.getMicrophoneStatus()" id="id-restart-call" class="restart-talk-button" type="button" (click)="interactCharacter()">
                    <app-stop></app-stop>
                </button>
            </div>
        </div>
    </div>
</div>
