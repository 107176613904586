import { Graphics } from "pixi.js";

export interface AnimationStruct {
    scaleMin?: number;
    scaleMax?: number;
    animationDuration?: number;
    containerChildrenMargin: number;
    containerChildren: Array<{
        name: string;
        scaling?: number;
        radius?: number;
        graphic: Graphics | null;
        color: string;
        width?: number;
        height?: number;
        minHeight?: number;
        maxHeight?: number;
        borderRadius?: number;
    }>;
}

export const idleAnimationStruct: AnimationStruct = {
    containerChildrenMargin: 80,
    containerChildren: [
        {
            name: "leftEye",
            width: 53,
            minHeight: 8,
            maxHeight: 68,
            borderRadius: 25,
            graphic: null,
            color: "0x5598FE"
        },
        {
            name: "rightEye",
            width: 53,
            minHeight: 8,
            maxHeight: 68,
            borderRadius: 25,
            graphic: null,
            color: "0x5598FE"
        }
    ]
};

export const listeningAnimationStruct: AnimationStruct = {
    containerChildrenMargin: 80,
    containerChildren: [
        {
            name: "leftEye",
            width: 53,
            minHeight: 62,
            maxHeight: 68,
            borderRadius: 25,
            graphic: null,
            color: "0x5598FE"
        },
        {
            name: "rightEye",
            width: 53,
            minHeight: 62,
            maxHeight: 68,
            borderRadius: 25,
            graphic: null,
            color: "0x5598FE"
        }
    ]
};

export const loadingAnimationStruct: AnimationStruct = {
    scaleMin: 0.5,
    scaleMax: 1.0,
    animationDuration: 2.5,
    containerChildrenMargin: 4,
    containerChildren: [
        {
            name: "bubble",
            scaling: 1,
            radius: 4.5 * 7,
            graphic: null,
            color: "0x5598FE"
        },
        {
            name: "bubble",
            scaling: 0.7,
            radius: 4.5 * 7,
            graphic: null,
            color: "0x5598FE"
        },
        {
            name: "bubble",
            scaling: 0.5,
            radius: 4.5 * 7,
            graphic: null,
            color: "0x5598FE"
        }
    ]
};

export const speakingAnimationStruct: AnimationStruct = {
    containerChildrenMargin: 4,
    containerChildren: [
        {
            name: "shape",
            width: 38,
            minHeight: 50,
            maxHeight: 88,
            borderRadius: 19,
            graphic: null,
            color: "0x5598FE"
        },
        {
            name: "shape",
            width: 38,
            minHeight: 50,
            maxHeight: 88,
            borderRadius: 19,
            graphic: null,
            color: "0x5598FE"
        },
        {
            name: "shape",
            width: 38,
            minHeight: 50,
            maxHeight: 88,
            borderRadius: 19,
            graphic: null,
            color: "0x5598FE"
        },
        {
            name: "shape",
            width: 38,
            minHeight: 50,
            maxHeight: 88,
            borderRadius: 19,
            graphic: null,
            color: "0x5598FE"
        },
        {
            name: "shape",
            width: 38,
            minHeight: 50,
            maxHeight: 88,
            borderRadius: 19,
            graphic: null,
            color: "0x5598FE"
        }
    ]
};
