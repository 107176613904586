<div class="modal fade bd-example-modal-lg" data-bs-backdrop='static' tabindex="-1" role="dialog" aria-hidden="true"
     [id]="idModal" [attr.aria-labelledby]="labelModal" (keydown.escape)="closeFeedback()">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="dislike-container">
                    <app-dislike width="20" height="20" stroke="#F8F8F8"></app-dislike>
                </div>
                <p class="feedback-title">{{ feedbackText }}</p>
                <button id="id-close-feedback" class="feedback-close" data-bs-dismiss="modal" type="button" aria-label="Close" (click)="closeFeedback()">
                    <app-close></app-close>
                </button>
            </div>
            <div class="modal-body">
                <textarea id="id-feedback-reason" [placeholder]="placeholderText" [(ngModel)]="additionalFeedback"></textarea>
                <ul class="feedback-reasons">
                    <li *ngFor="let checkbox of checkboxes">
                        <input id="id-checkbox-option" type="checkbox" [id]="checkbox.id" name="reason-1" [(ngModel)]="checkbox.checked" (change)="onCheckboxChange(checkbox.id)" />
                        <label class="checkbox-label" [for]="checkbox.id">
                            <span class="feedback-checkbox">
                                <span class="check-icon"></span>
                            </span>
                        </label>
                        <label [for]="checkbox.id">{{ checkbox.text }}</label>
                    </li>
                </ul>
            </div>
            <div class="modal-footer">
                <button id="id-negative-feedback" data-bs-dismiss="modal" class="submit-feedback" type="button"
                        (click)="submitNegativeReaction()" [disabled]="!formFilled()">{{ buttonText }}</button>
            </div>
        </div>
    </div>
</div>
