import { ElementRef, Injectable, Renderer2 } from '@angular/core';

import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SoundWaveService {
    soundWaveContainerWidth: number = 160;
    maximumSoundWaves: number = Math.floor((this.soundWaveContainerWidth - 20) / 4);
    minimalSpeechValue: number = 0.002;
    maximumSpeechValue: number = 1.0;
    minimalSoundWaveHeight: number = 15;
    maximalSoundWaveHeight: number = 100;

    soundWaveRenderElement!: ElementRef;
    soundWaveHeight!: number;
    soundWaveList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    constructor() {}

    // getters
    getSoundWaveRenderElement() {
        return this.soundWaveRenderElement;
    }

    getSoundWaveHeight() {
        return this.soundWaveHeight;
    }

    // setters
    setSoundWaveRenderElement(element: ElementRef) {
        this.setSoundWaveWidth(element);
        this.soundWaveRenderElement = element;
    }

    setSoundWaveWidth(element: ElementRef) {
        const elementWidth = element.nativeElement.parentElement.getBoundingClientRect().width;
        this.soundWaveContainerWidth = parseFloat(elementWidth) < 1 ? 160 : elementWidth;
        this.maximumSoundWaves = Math.floor((this.soundWaveContainerWidth - 20) / 4);
    }

    setSoundWaveHeight(decimal: number) {
        // Ensure the decimal is clamped between minimalSpeechValue and maximumSpeechValue
        decimal = Math.max(this.minimalSpeechValue, Math.min(this.maximumSpeechValue, decimal));
        // Normalize the decimal value to the range [0, 1]
        const normalizedValue = (decimal - this.minimalSpeechValue) / (this.maximumSpeechValue - this.minimalSpeechValue);
        // Scale the normalized value to the range of sound wave heights
        this.soundWaveHeight = normalizedValue * (this.maximalSoundWaveHeight - this.minimalSoundWaveHeight) + this.minimalSoundWaveHeight;
    }

    setSoundwaveBars(soundWaveList: string[]) {
        this.soundWaveList.next(soundWaveList);
    }

    // methods
    clearSoundWave() {
        if (this.getSoundWaveRenderElement()) {
            const htmlElement = this.getSoundWaveRenderElement().nativeElement;

            Array.from(htmlElement.childNodes).forEach(c => {
                if (c instanceof HTMLElement) { c.remove(); }
            });
        }
    }

    updateSoundWaveList() {
        let currentList = this.soundWaveList.getValue();
        currentList.push(this.getSoundWaveHeight().toString());

        if (currentList.length > this.maximumSoundWaves) currentList.shift();
        this.setSoundwaveBars(currentList)
    }
}
