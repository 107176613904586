import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MultimediaService } from "../../../core/services/multimedia.service";
import { EventService } from "../../../core/services/event.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrl: './content-modal.component.scss'
})
export class ContentModalComponent implements OnInit, OnDestroy {
    @ViewChild('videoModal') videoPlayer!: ElementRef;
    @Input() idModal!: string;
    @Input() labelModal!: string;
    mediaType: string = '';
    mediaSource: string = '';

    multimediaSubscription!: Subscription;

    constructor(
        private event: EventService,
        private multimedia: MultimediaService){ }

    ngOnInit(): void {
        this.multimediaSubscription = this.multimedia.getMultimediaData().subscribe(
            ({ mediaType, source }) => {
                this.mediaType = mediaType;
                this.mediaSource = source;
            },
        );
    }

    closeModal() {
        if (this.videoPlayer) {
            this.videoPlayer.nativeElement.pause();
            this.videoPlayer.nativeElement.currentTime = 0;
        }

        // Reset mediaType and mediaSource before assigning new values
        if (this.mediaType === 'document') { this.mediaSource = ''; }
        this.event.blurEfectEvent.emit(false);
    }

    // Reset mediaType and mediaSource
    ngOnDestroy() {
        if(this.multimediaSubscription) { this.multimediaSubscription.unsubscribe(); }
    }
}
