import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {
    multimediaDataSubject = new Subject<{
        mediaType: string;
        source: string;
    }>();

    constructor() {}

    // getters
    getMultimediaData() {
        return this.multimediaDataSubject.asObservable();
    }

    getAttachmentType(fileName: string) {
        const extension = fileName.split('.').pop()!.toLowerCase();
        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return 'image';
            case 'mp4':
                return 'video';
            case 'pdf':
            case 'txt':
            case 'docx':
                return 'document';
            default:
                return 'unknown';
        }
    };

    // methods
    emitMediaData(mediaType: string, source: any): void {
        this.multimediaDataSubject.next({ mediaType, source });
    }
}
