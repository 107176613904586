export class AttachmentDto {
    name: string;
    size: number;
    url: string;
    type: string;
    extension: string;

    constructor(name: string, size: number, url: string, type: string, extension: string) {
        this.name = name;
        this.size = size;
        this.url = url;
        this.type = type;
        this.extension = extension;
    }
}
