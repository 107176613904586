import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import {WebSocketService} from "../../../core/services/web-socket.service";


@Component({
  selector: 'app-lost-network',
  templateUrl: './lost-network.component.html',
  styleUrl: './lost-network.component.scss'
})
export class LostNetworkComponent implements OnInit {
    companyLogo: string = "assets/icons/logo.svg"
    companyName: string = "Exafy"
    lostConnectionImage: string = "assets/images/lostConnection.png"
    lostConnectionName: string = "Lost Connection"
    lostConnectionDescription: string = "You have lost connection with system"
    reloadButton: string = "Reload"

    constructor (
        protected socket: WebSocketService,
        private title: Title) {
    }

    ngOnInit(): void {
        // const message: WebSocketMessage = {
        //     type: "text",
        //     content: 'An error occurred, please check that the network connection is stable and try again.',
        //     language: 'en-US'
        // }
        //
        // this.message.setMessage('ai', message);
    }
}
