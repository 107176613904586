<div class="box-account">
    <div class="account-wrapper">
        <!--<p class="account-name">{{ agentName }}</p>-->
        <button
            class="account-container"
            id="id-account"
            [disabled]="!isInteractionAllowed"
            (click)="toggleDropdown()"
            tabindex="0"
        >
            <div *ngIf="isVisibleMenu" class="account-menu">
                <div *ngFor="let item of items">
                    <div
                        class="menu-item"
                        id="id-account-option"
                        (click)="toggleOption(item)"
                        tabindex="0"
                    >
                        <img class="menu-item-img" [src]="item.icon" alt="Account icon">
                        <p class="menu-item-name">{{ item.name }}</p>
                    </div>
                </div>
            </div>
            <img class="button-background" [src]="accountImage" alt="Account icon">
        </button>
    </div>
</div>
