import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { LanguageService } from "../../../core/services/language.service";
import { MessageService } from "../../../core/services/message.service";
import { EventService } from "../../../core/services/event.service";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss'
})
export class FeedbackComponent implements OnInit, OnDestroy {
    @Input() idModal!: string;
    @Input() labelModal!: string;

    placeholderText: string = 'Type your additional feedback...';
    feedbackText: string = 'Provide additional feedback';
    harmfulCheckboxText: string = 'This is harmful/unsafe';
    helpfulCheckboxText: string = 'This isn’t helpful';
    trueCheckBoxText: string = 'This isn’t true';
    buttonText: string = 'Submit';
    additionalFeedback!: string;

    checkboxes = [
        { id: 'reason-1', text: this.harmfulCheckboxText, checked: false},
        { id: 'reason-2', text: this.trueCheckBoxText, checked: false },
        { id: 'reason-3', text: this.helpfulCheckboxText, checked: false },
    ];

    translateSubscription!: Subscription;

    constructor(
        private event: EventService,
        private language: LanguageService,
        private messages: MessageService) { }

    ngOnInit() {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)

            Object.assign(this, translate.typography);
            const checkboxTexts = [this.harmfulCheckboxText, this.trueCheckBoxText, this.helpfulCheckboxText];
            this.checkboxes.forEach((checkbox, index) => {checkbox.text = checkboxTexts[index]; });
        });
    }

    closeFeedback() {
        this.resetFeedbackFields();
        this.event.blurEfectEvent.emit(false);
    }

    resetFeedbackFields(){
        this.additionalFeedback = '';
        this.checkboxes.forEach(checkbox => {checkbox.checked = false; });
    }

    submitNegativeReaction() {
        const index = this.messages.getMessageIndex();
        const selectedCheckbox = this.checkboxes.find(checkbox => checkbox.checked);
        const selectedOption = selectedCheckbox?.text;

        if (index != null) {
            this.messages.setMessageFeedback(index, false, this.additionalFeedback, selectedOption);
            this.event.feedbackSubmitted.emit(index);
            this.closeFeedback();
        } else {
            console.warn("Invalid message index.");
        }
    }

    onCheckboxChange(selectedCheckboxId: string) {
        this.checkboxes.forEach(checkbox => {
            if (checkbox.id !== selectedCheckboxId) {
                checkbox.checked = false;
            }
        });
    }

    formFilled(): boolean {
        return !!(
            this.additionalFeedback
            && this.additionalFeedback.trim() !== ""
            && this.checkboxes.some(checkbox => checkbox.checked));
    }

    ngOnDestroy() {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
    }
}
