import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { Subscription } from "rxjs";

import { AnimationService } from "../../../core/services/animation.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { EventService } from "../../../core/services/event.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit, OnDestroy {
    @ViewChild('loaderContainer') loaderContainer!: ElementRef;
    companyLogo: string = "assets/icons/logo.svg"

    isLoadingShown: boolean = false;

    loadingBarSubscription!: Subscription;

    constructor(private animation: AnimationService,
                private event: EventService,
                private cdr: ChangeDetectorRef,
                private visibility: VisibilityService) { }

    ngOnInit(): void {
        this.loadingBarSubscription = this.visibility.getVisibility('avatar-loader').subscribe(async loading => {
            this.isLoadingShown = loading;
        });
    }

    ngOnDestroy(): void {
        if (this.loadingBarSubscription) { this.loadingBarSubscription.unsubscribe(); }
    }
}
