<div class="box-header">
    <div class="header-wrapper">
        <button id="id-new-chat" type="button" class="new-chat-button" [disabled]="!isInteractionAllowed || talkStarted" (click)="newChat()">
            <p class="company-name">{{ companyName }}</p>
        </button>
        <div class="inline-header">
            <app-account></app-account>
            <app-languages></app-languages>
        </div>
    </div>
    <hr class="line">
</div>
