<div class="box-languages">
    <button
        class="language-container"
        id="id-language"
        [disabled]="!isInteractionAllowed"
        (click)="toggleDropdown()"
        tabindex="0"
    >
        <img class="button-background" [src]="selectedLanguage.icon" alt="Selected language icon">

        <div *ngIf="isVisibleMenu" class="language-menu">
            <div *ngFor="let language of languages">
                <div
                    class="menu-item"
                    [ngClass]="getClass(language)"
                    (click)="toggleLanguage(language)"
                    tabindex="0"
                >
                    <img class="menu-item-img" [src]="language.icon" alt="Language icon">
                    <p class="menu-item-name" >{{ language.name }}</p>
                </div>
            </div>
        </div>
    </button>
</div>
