import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-attachment',
    template: `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16.0002" cy="16.0002" r="11.8" [attr.stroke]="stroke" stroke-width="2"/>
            <path d="M11.2002 16H20.8002" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round"/>
            <path d="M16 20.8003L16 11.2003" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round"/>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-controls-color: #D9D9D9;
        }
        svg path {
            stroke: var(--stroke-controls-color);
        }
    `]
})
export class AttachmentComponent {
    @Input() stroke: string = 'var(--stroke-controls-color)';
}
