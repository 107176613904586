import { Injectable } from '@angular/core';
import { Application, Container, Graphics } from 'pixi.js';
import { speakingAnimationStruct, AnimationStruct } from './structure';  // Adjust the import path as needed

export class ExaSpeakingAnimation {
    app: Application;
    container!: Container;
    containerChildren: AnimationStruct['containerChildren'];
    containerChildrenMargin: number;

    constructor(app: Application) {
        this.app = app;
        this.containerChildren = speakingAnimationStruct.containerChildren;
        this.containerChildrenMargin = speakingAnimationStruct.containerChildrenMargin;
    }

    init() {
        this.container = new Container();
        this.app.stage.addChild(this.container);
        this.render();
        this.app.ticker.add(this.animate, this);
    }

    render() {
        this.prepare();

        let lastKnownX = 0;
        const maxSphereWidth = this.getMaxSphereWidth();
        const maxSphereHeight = this.getMaxSphereHeight();

        this.containerChildren.forEach(asset => {
            // @ts-ignore
            asset.graphic.y = (maxSphereHeight - asset.graphic.height) / 2;
            // @ts-ignore
            asset.graphic.x = lastKnownX > 0 ? lastKnownX + (maxSphereWidth - asset.graphic.width) / 2 : 0;

            // @ts-ignore
            this.setContainerChild(asset.graphic);
            lastKnownX += maxSphereWidth;
        });

        this.setContainerPivotXY(
            maxSphereWidth * this.containerChildren.length / 2,
            maxSphereHeight
        );

        this.setContainerXY(
            this.app.renderer.width / 2,
            this.app.renderer.height / 2
        );
    }

    prepare() {
        this.containerChildren = this.containerChildren.map((asset, assetIndex) => {
            asset.graphic = new Graphics();
            asset.graphic.fill(0x5598FE);
            // @ts-ignore
            asset.graphic.roundRect(0, 0, asset.width, (assetIndex + 1) % 2 === 0 ? asset.minHeight : asset.maxHeight, asset.borderRadius);
            asset.graphic.fill();

            return asset;
        });
    }

    setContainerXY(x: number, y: number) {
        this.container.x = x;
        this.container.y = y;
    }

    setContainerPivotXY(x: number, y: number) {
        this.container.pivot.x = x;
        this.container.pivot.y = y;
    }

    setContainerChild(graphic: Graphics) {
        this.container.addChild(graphic);
    }

    getMaxSphereWidth() {
        let maxSphereWidth = 0;
        this.containerChildren.forEach(asset => {
            // @ts-ignore
            maxSphereWidth = Math.max(maxSphereWidth, asset.graphic.width);
        });

        return maxSphereWidth + this.containerChildrenMargin;
    }

    getMaxSphereHeight() {
        let maxSphereHeight = 0;
        this.containerChildren.forEach(asset => {
            // @ts-ignore
            maxSphereHeight = Math.max(maxSphereHeight, asset.graphic.height);
        });

        return maxSphereHeight + this.containerChildrenMargin;
    }

    destroy() {
        this.app.ticker.remove(this.animate, this);
        this.app.stage.removeChild(this.container);
        this.container.destroy({ children: true });
    }

    animate() {
        const value = performance.now() / 1000;
        const containerChildren = this.containerChildren;

        containerChildren.forEach((asset, assetIndex) => {
            const maxHeight = asset.maxHeight;
            const minHeight = asset.minHeight;
            // @ts-ignore
            const height = minHeight + (maxHeight - minHeight) * (0.5 + 0.5 * Math.sin((value + assetIndex * 0.5) * Math.PI * 2 / 1.3));

            if (asset.graphic) {
                const x = asset.graphic.x;
                asset.graphic.clear();

                asset.graphic.fill(0x5598FE);
                // @ts-ignore
                asset.graphic.roundRect(0, 0, asset.width, height, asset.borderRadius);
                asset.graphic.fill();

                asset.graphic.x = x;
                // @ts-ignore
                asset.graphic.y = (maxHeight - height) / 2;

                this.setContainerChild(asset.graphic);
            }
        });
    }
}
