import { Application, Container, Graphics } from 'pixi.js';
import { AnimationStruct, listeningAnimationStruct } from './structure';  // Adjust the import path as needed


export class ExaListeningAnimation {
    app: Application; // Instanse of the PixiJS Application
    container!: Container; // A container for holding graphical elements
    containerChildren: AnimationStruct['containerChildren']; // An array of objects representing the graphical elements to be animated
    containerChildrenMargin: number;
    blinkSpeed: number;
    blinkingTime: number;
    blinkingCooldown: number;
    blinkingAnimationTime: number;
    blinkingTimeCounter: number;
    transitionDuration: number;
    state: string;

    constructor(app: Application) {
        this.app = app;

        this.containerChildren = listeningAnimationStruct.containerChildren;
        this.containerChildrenMargin = listeningAnimationStruct.containerChildrenMargin;
        this.blinkSpeed = 1;
        this.blinkingTime = 0.5; // Duration for one blinking cycle
        this.blinkingCooldown = Math.floor(Math.random() * 6) + 1; // Time between blinking animations.
        this.blinkingAnimationTime = Math.floor(Math.random() * 4) + 1; // Duration for the blinking animation phase
        this.blinkingTimeCounter = 0;
        this.transitionDuration = 0.65;
        this.state = 'blinking';
    }

    // Initializes the animation by creating a container, adding it to the stage, and setting up the animation loop with app.ticker
    init() {
        this.container = new Container();
        this.app.stage.addChild(this.container);
        this.render();
        this.app.ticker.add(this.animate, this);
    }

    render() {
        this.prepare();

        let lastKnownX = 0;
        const maxSphereWidth = this.getMaxSphereWidth();
        const maxSphereHeight = this.getMaxSphereHeight();

        this.containerChildren.forEach(asset => {
            // @ts-ignore
            asset.graphic.y = (maxSphereHeight - asset.graphic.height) / 2;
            // @ts-ignore
            asset.graphic.x = lastKnownX > 0 ? lastKnownX + (maxSphereWidth - asset.graphic.width) / 2 : 0;

            // @ts-ignore
            this.setContainerChild(asset.graphic);
            lastKnownX += maxSphereWidth;
        });

        this.setContainerPivotXY(
            this.container.width / 2,
            maxSphereWidth
        );

        this.setContainerXY(
            this.app.renderer.width / 2,
            this.app.renderer.height / 2
        );
    }

    // Initializes the graphical elements (containerChildren) by creating Pixi.js Graphics objects.
    prepare() {
        this.containerChildren = this.containerChildren.map((asset) => {
            asset.graphic = new Graphics();
            asset.graphic.fill(0x5598FE);
            // @ts-ignore
            asset.graphic.roundRect(0, 0, asset.width, asset.maxHeight, asset.borderRadius);
            asset.graphic.fill();
            return asset;
        });
    }

    setContainerXY(x: number, y: number) {
        this.container.x = x;
        this.container.y = y;
    }

    setContainerPivotXY(x: number, y: number) {
        this.container.pivot.x = x;
        this.container.pivot.y = y;
    }

    setContainerChild(graphic: Graphics) {
        this.container.addChild(graphic);
    }

    getMaxSphereWidth() {
        let maxSphereWidth = 0;
        this.containerChildren.forEach(asset => {
            // @ts-ignore
            maxSphereWidth = Math.max(maxSphereWidth, asset.graphic.width);
        });

        return maxSphereWidth + this.containerChildrenMargin;
    }

    getMaxSphereHeight() {
        let maxSphereHeight = 0;
        this.containerChildren.forEach(asset => {
            // @ts-ignore
            maxSphereHeight = Math.max(maxSphereHeight, asset.graphic.height);
        });

        return maxSphereHeight + this.containerChildrenMargin;
    }

    destroy() {
        this.app.ticker.remove(this.animate, this);
        this.app.stage.removeChild(this.container);
        this.container.destroy({ children: true });
    }

    getRandomValue(min: number, max: number) {
        const minMultiple = Math.ceil(min / (this.blinkingTime * 2));
        const maxMultiple = Math.floor(max / (this.blinkingTime * 2));

        const randomMultiple = Math.floor(Math.random() * (maxMultiple - minMultiple + 1)) + minMultiple;

        return randomMultiple * (this.blinkingTime * 2);
    }

    animate(delta: any) {
        const value = performance.now() / 1000;
        const timing = delta / this.app.ticker.FPS;

        const blinkPhase = Math.sin(value * Math.PI * this.blinkSpeed);
        const movePhase = Math.sin(value);
        const containerChildren = this.containerChildren;

        containerChildren.forEach((asset, assetIndex) => {
            const moveX = Math.sin(movePhase * 2) * 10;
            const moveY = Math.cos(movePhase * 3) * 5;

            if (this.state === 'blinking') {
                const maxHeight = asset.maxHeight;
                const minHeight = asset.minHeight;
                // @ts-ignore
                const height = minHeight + (maxHeight - minHeight) * (0.5 + 0.5 * blinkPhase);

                if (asset.graphic) {
                    asset.graphic.clear();
                    asset.graphic.fill(parseInt(asset.color, 16));
                    // @ts-ignore
                    asset.graphic.roundRect(0, 0, asset.width, height, asset.borderRadius);
                    asset.graphic.fill();
                    asset.graphic.x = moveX + (assetIndex * (this.getMaxSphereWidth() + (this.getMaxSphereWidth() - asset.graphic.width) / 2));
                    // @ts-ignore
                    asset.graphic.y = (maxHeight - height) / 2 + moveY;

                    this.setContainerChild(asset.graphic);
                }
            } else if (this.state === 'cooldown') {
                containerChildren.forEach((asset, assetIndex) => {
                    // @ts-ignore
                    asset.graphic.x = moveX + (assetIndex * (this.getMaxSphereWidth() + (this.getMaxSphereWidth() - asset.graphic.width) / 2));
                    // @ts-ignore
                    asset.graphic.y = moveY;
                });
            }
        });

        this.blinkingTimeCounter += timing;

        if (this.state === 'blinking') {
            if (this.blinkingTimeCounter >= this.blinkingAnimationTime - this.transitionDuration) {
                const transitionPhase = (this.blinkingTimeCounter - (this.blinkingAnimationTime - this.transitionDuration)) / this.transitionDuration;

                containerChildren.forEach((asset, assetIndex) => {
                    const maxHeight = asset.maxHeight;
                    const minHeight = asset.minHeight;
                    // @ts-ignore
                    const height = minHeight + (maxHeight - minHeight) * transitionPhase;
                    const moveX = Math.sin(movePhase * 2) * 10;

                    // @ts-ignore
                    asset.graphic.clear();
                    // @ts-ignore
                    asset.graphic.beginFill(parseInt(asset.color, 16));
                    // @ts-ignore
                    asset.graphic.drawRoundedRect(0, 0, asset.width, height, asset.borderRadius);
                    // @ts-ignore
                    asset.graphic.endFill();
                    // @ts-ignore
                    asset.graphic.x = moveX + (assetIndex * (this.getMaxSphereWidth() + (this.getMaxSphereWidth() - asset.graphic.width) / 2));
                    // @ts-ignore
                    asset.graphic.y = (maxHeight - height) / 2;

                    // @ts-ignore
                    this.setContainerChild(asset.graphic);
                });

                if (this.blinkingTimeCounter >= this.blinkingAnimationTime) {
                    this.state = 'cooldown';
                    this.blinkingTimeCounter = 0;
                    this.blinkingAnimationTime = this.getRandomValue(this.blinkingTime, 4);
                }
            }
        } else if (this.state === 'cooldown') {
            if (this.blinkingTimeCounter >= this.blinkingCooldown) {
                this.state = 'blinking';
                this.blinkingTimeCounter = 0;
                this.blinkingCooldown = this.getRandomValue(this.blinkingTime, 4);
            }
        }

        this.container.pivot.y = (this.getMaxSphereWidth() / 2) + (this.getMaxSphereHeight() / 4) * 0.5 + 0.5;
    }
}
