import { Component } from '@angular/core';

@Component({
    selector: 'app-copy',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path d="M13.2422 15H5.27344C4.30408 15 3.51562 14.2115 3.51562 13.2422V5.27344C3.51562 4.30408 4.30408 3.51562 5.27344 3.51562H13.2422C14.2115 3.51562 15 4.30408 15 5.27344V13.2422C15 14.2115 14.2115 15 13.2422 15ZM5.27344 4.6875C4.95035 4.6875 4.6875 4.95035 4.6875 5.27344V13.2422C4.6875 13.5653 4.95035 13.8281 5.27344 13.8281H13.2422C13.5653 13.8281 13.8281 13.5653 13.8281 13.2422V5.27344C13.8281 4.95035 13.5653 4.6875 13.2422 4.6875H5.27344ZM2.34375 10.3125H1.75781C1.43472 10.3125 1.17188 10.0497 1.17188 9.72656V1.75781C1.17188 1.43472 1.43472 1.17188 1.75781 1.17188H9.72656C10.0497 1.17188 10.3125 1.43472 10.3125 1.75781V2.31445H11.4844V1.75781C11.4844 0.788452 10.6959 0 9.72656 0H1.75781C0.788452 0 0 0.788452 0 1.75781V9.72656C0 10.6959 0.788452 11.4844 1.75781 11.4844H2.34375V10.3125Z" fill="#31405A"/>
        </svg>
    `,
})
export class CopyComponent {

}
