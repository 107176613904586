import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boldText'
})
export class BoldTextPipe implements PipeTransform {

    transform(value: string): string {
        if (!value) return value;
        // Change **text** with <strong>text</strong>
        return value.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    }

}
