<div class="box-shortcut">
    <div class="shortcut-container-wrapper">
        <ul class="shortcut-container-list">
            <button
                *ngFor="let shortcut of shortcutsList"
                id="id-shortcut"
                class="shortcut-container-item"
                (click)="onShortcutSubmit(shortcut.text)"
                [disabled]="microphone.getMicrophoneStatus()"
                tabindex="0"
            >
                <p class="shortcut-name">{{ shortcut.text }}</p>
            </button>
        </ul>
    </div>
</div>
