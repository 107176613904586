import { AfterViewChecked, Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appAutoResize]'
})
export class AutoResizeDirective implements AfterViewChecked {
    constructor(private element: ElementRef, private renderer: Renderer2) {}

    @HostListener('input')
    onInput(): void {
        this.adjust();
    }

    // This hook is called after every check of the component's view, onInput is called within this hook
    ngAfterViewChecked(): void {
        this.onInput();
    }

    adjust(): void {
        const textarea = this.element.nativeElement;
        this.renderer.setStyle(textarea, 'height', '20px');
        this.renderer.setStyle(textarea, 'height', `${textarea.scrollHeight}px`);
    }
}
