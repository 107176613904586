import { Component } from '@angular/core';

@Component({
    selector: 'app-edit',
    template: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Editing" clip-path="url(#clip0_552_246)">
                <g id="Group">
                    <path id="Vector" d="M15.508 1.85175L14.1482 0.492004C13.4923 -0.164001 12.4249 -0.164001 11.7688 0.492004L0.891144 11.3697C0.790479 11.4704 0.721879 11.5986 0.6939 11.7382L0.0140028 15.1375C-0.033253 15.3739 0.0407312 15.6183 0.211246 15.7888C0.347822 15.9253 0.531797 16 0.721109 16C0.768173 16 0.815477 15.9954 0.862492 15.986L4.26178 15.3061C4.40139 15.2782 4.5296 15.2095 4.63026 15.1089L15.508 4.23123C15.508 4.23123 15.508 4.23123 15.508 4.23118C16.164 3.57522 16.164 2.50781 15.508 1.85175ZM3.7649 13.9347L1.64036 14.3597L2.06533 12.2352L10.9189 3.38139L12.6186 5.08106L3.7649 13.9347ZM14.4882 3.21145L13.6384 4.06129L11.9387 2.36161L12.7885 1.51183C12.8822 1.41808 13.0347 1.41804 13.1285 1.51178L14.4882 2.87153C14.582 2.96522 14.582 3.11776 14.4882 3.21145Z" fill="#C6D0DA"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_552_246">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    `,
})
export class EditComponent {

}
