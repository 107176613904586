import { ChangeDetectorRef, Injectable } from '@angular/core';
import { EventService } from "./event.service";
import { SoundWaveService } from "./soundwave.service";
import { VisibilityService } from "./visibility.service";
import {Subject} from "rxjs";

declare var vad: any;

@Injectable({
    providedIn: 'root'
})
export class MicrophoneService {
    mediaStream: MediaStream | null = null;
    mediaAudioRecorder: any | null = null;
    microphoneStatus: boolean = false;
    microphoneTalkStatus: boolean = false;
    positiveSpeechThreshold: number = 0.8;
    speechEndTimeout: any = null;

    waveAnimationRenderEvent = new Subject<any>();
    closeMicrophoneEvent = new Subject<File>();
    talkStarted: boolean = false;
    robotTalking: boolean = false;

    vadInstance: any;

    constructor(
        private event: EventService,
        private soundwave: SoundWaveService,
        private visibility: VisibilityService)
    {
        this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        (window as any).simulateMicrophoneStop = this.simulateMicrophoneStop.bind(this);
    }

    // getters
    getMicrophoneStatus (): boolean {
        return this.microphoneStatus;
    }

    getMicrophoneTalkStatus (): boolean {
        return this.microphoneTalkStatus;
    }

    getRobotTalkingStatus (): boolean {
        return this.robotTalking;
    }

    // setters
    setMicrophoneStatus (s: boolean) {
        s ? this.visibility.showComponent('avatar-soundwave') : this.visibility.showComponent('avatar-input');
        this.microphoneStatus = s;
    }

    setMicrophoneTalkStatus (s: boolean) {
        this.microphoneTalkStatus = s;
    }

    setRobotTalkingStatus (s: boolean) {
        this.robotTalking = s;
    }

    // methods
    async onMicrophoneClick(changeDetector: ChangeDetectorRef, micState: boolean) {
        if (micState) {
            this.event.setStartedVoiceConversation(true);
            await this.startMicrophone();
        } else if (!micState) {
            this.resetMicrophone();
            this.event.setStartedVoiceConversation(false);
        }

        // changeDetector.detectChanges(); TODO: At the end it looks like this is causing trouble here.
    }

    resetMicrophone() {
        this.soundwave.setSoundwaveBars([]);
        this.soundwave.clearSoundWave();
        this.stopMicrophoneAccess();
    }

    async startMicrophone () {
        if (!this.isMicrophoneSupported()) return;

        // If the microphone is already started, return
        if (this.mediaStream) return;

        try {
            await this.getMicrophoneAccess();
        } catch (err) {
            throw new Error(`could not get media stream: ${err}`);
        }
    }

    stopMicrophoneAccess () {
        if (this.mediaStream === null) return;
        if (this.mediaAudioRecorder === null) return;

        this.mediaAudioRecorder.destroy();
        this.vadInstance = null;  // Clean up the VAD instance

        this.mediaAudioRecorder = null;
        this.mediaStream = null;

        if (this.talkStarted) {
            this.setMicrophoneTalkStatus.call(this, false);
        } else {
            this.setMicrophoneStatus.call(this, false);
        }
    }

    async getMicrophoneAccess () {
        try {
            if (this.talkStarted) {
                this.setMicrophoneTalkStatus(true);
            } else {
                this.setMicrophoneStatus(true);
            }

            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    channelCount: 1,
                    echoCancellation: true,
                    autoGainControl: true,
                    noiseSuppression: true
                }
            });

            if (this.mediaAudioRecorder) {
                this.mediaAudioRecorder.destroy();
                this.mediaAudioRecorder = null;
            }

            this.mediaAudioRecorder = await this.getMicDriver(this.mediaStream);
            if (!this.mediaAudioRecorder) return;
            this.mediaAudioRecorder.start();
        } catch (err) {
            if (this.talkStarted) {
                this.setMicrophoneTalkStatus(false);
            } else {
                this.setMicrophoneStatus(false);
            }

            throw new Error(`Could not initialize recording process: ${err}`);
        }
    }

    async getMicDriver(mediaStream: MediaStream) {
        this.vadInstance = await vad.MicVAD.new({
            stream: mediaStream,
            positiveSpeechThreshold: this.positiveSpeechThreshold,
            onSpeechStart: () => {
                console.log("SPEECH STARTED");
            },
            onSpeechEnd: (audio: Float32Array) => {
                this.closeMicrophoneEvent.next(
                    this.convertWavToFile(this.convertFloat32ArrayToWav(audio, 16000))
                );
            },
            onFrameProcessed: (probabilities: any) => {
                this.waveAnimationRenderEvent.next(probabilities);
                //console.log(probabilities);
                if (!this.talkStarted) {
                    if (probabilities.isSpeech < this.positiveSpeechThreshold) {
                        if (this.speechEndTimeout === null) {
                            this.speechEndTimeout = setTimeout(() => {
                                this.closeMicrophoneEvent.next(
                                    this.convertWavToFile(this.convertFloat32ArrayToWav(new Float32Array(), 16000))
                                );
                                // restart timer
                                this.speechEndTimeout = null;
                            }, 2000);
                        }
                    } else if (probabilities.isSpeech > this.positiveSpeechThreshold) {
                        clearTimeout(this.speechEndTimeout);
                        this.speechEndTimeout = null;
                    }
                }
            }
        });

        return this.vadInstance;
    }

    private convertWavToFile (audio: Blob): File {
        return new File([audio], "audio.wav", {type: audio.type});
    }

    private convertFloat32ArrayToWav (audioBuffer: Float32Array, sampleRate: number): Blob {
        if (audioBuffer.length === 0) {
            return new Blob();
        }

        const bufferLength = audioBuffer.length;
        const wavHeaderSize = 44;
        const totalLength = bufferLength * 2 + wavHeaderSize;

        const dataView = new DataView(new ArrayBuffer(totalLength));
        const writeString = (view: DataView, offset: number, string: string) => {
            for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        };

        writeString(dataView, 0, 'RIFF');
        dataView.setUint32(4, 36 + bufferLength * 2, true);
        writeString(dataView, 8, 'WAVE');

        writeString(dataView, 12, 'fmt ');
        dataView.setUint32(16, 16, true);
        dataView.setUint16(20, 1, true);
        dataView.setUint16(22, 1, true);
        dataView.setUint32(24, sampleRate, true);
        dataView.setUint32(28, sampleRate * 2, true);
        dataView.setUint16(32, 2, true);
        dataView.setUint16(34, 16, true);

        writeString(dataView, 36, 'data');
        dataView.setUint32(40, bufferLength * 2, true);

        let offset = 44;
        for (let i = 0; i < bufferLength; i++, offset += 2) {
            const s = Math.max(-1, Math.min(1, audioBuffer[i]));
            dataView.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }

        return new Blob([dataView], { type: 'audio/wav' });
    }

    private isMicrophoneSupported() {
        return navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function';
    }

    loadScript(scriptUrl: string): Promise<void> {
        return new Promise((resolve, reject) => {
            if (document.querySelector(`script[src="${scriptUrl}"]`)) {
                // If the script is already loaded, resolve immediately
                return resolve();
            }
            const scriptElement = document.createElement('script');
            scriptElement.src = scriptUrl;
            scriptElement.onload = () => resolve();
            scriptElement.onerror = () => reject(new Error(`Failed to load script: ${scriptUrl}`));
            document.body.appendChild(scriptElement);
        });
    }

    simulateMicrophoneStop(file: Blob) {
        this.closeMicrophoneEvent.next(
            this.convertWavToFile(file)
        );
    }
}


// async function loadWavAsBlob(url) {
//     try {
//         // Fetch the WAV file from the provided URL
//         const response = await fetch(url);
//
//         // Check if the response is successful
//         if (!response.ok) {
//             throw new Error(`Failed to fetch the file: ${response.statusText}`);
//         }
//
//         // Read the response as an ArrayBuffer
//         const arrayBuffer = await response.arrayBuffer();
//
//         // Convert the ArrayBuffer to a Blob (with MIME type audio/wav)
//         const blob = new Blob([arrayBuffer], { type: 'audio/wav' });
//
//         // Log the Blob object
//         console.log('WAV file Blob:', blob);
//
//         return blob;
//     } catch (error) {
//         console.error('Error loading WAV file:', error);
//     }
// }
//
// (async () => {
//     const data = await loadWavAsBlob("https://bf66-109-165-195-159.ngrok-free.app/assets/audio/bdba3ad9-8217-46b3-91f0-eecd09024335.wav");
//
//     window.simulateMicrophoneStop(data);
// })()
